
export default {
  name: 'TabBar',
  computed: {
    tabBarItems() {
      return [
        {
          name: this.$t('main'),
          key: 'home',
          route: 'index',
          to: '/',
          iconClass: 'i i-home',
        },
        {
          name: this.$t('product_catalog_short'),
          key: 'catalog',
          route: 'categories',
          to: '/categories',
          iconClass: 'i i-search-tab-bar',
        },
        {
          name: this.$t('market.cart'),
          key: 'cart',
          route: 'cart',
          to: '/cart',
          iconClass: 'i i-cart',
        },
        {
          name: this.$t('favorites'),
          key: 'favorites',
          route: 'favorites',
          to: '/favorites',
          iconClass: 'i i-favorite',
        },
        {
          name: this.$t('market.profile_short'),
          key: 'profile',
          route: 'account-profile',
          to: '/',
          iconClass: 'i i-profile-tab-bar',
        },
      ]
    },
  },
}
