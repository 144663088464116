import { useRoute, useStore } from '@nuxtjs/composition-api'
import { computed } from 'vue'
import { useMarketStore } from '~/stores/market'

export default {
  __name: 'TapBarItem',
  props: {
  item: {
    type: Object,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const route = useRoute()

const marketStore = useMarketStore()
const store = useStore()
const activeClrClass = 'text-[#7C62E3]'
const baseClrClass = 'text-[#50557A]'
const favoritesCount = computed(
  () => marketStore.favoritesCount || marketStore.anonFavoriteList.length,
)
const cartCount = computed(() => marketStore.cartCount)
const isAuthenticated = computed(() => Boolean(store.getters.isAuthenticated))
const itemClass = computed(() => {
  const routeName = route.value?.name
  if (routeName?.includes(props.item.route)) {
    return `${props.item.iconClass} ${activeClrClass} text-[22px]`
  } else {
    return `${props.item.iconClass} ${baseClrClass} text-[22px]`
  }
})

const linkFrom = item => {
  if (item?.key === 'profile') {
    return isAuthenticated.value ? 'account-profile' : toAuthentication()
  }
  return item?.to
}
const toAuthentication = () => {
  if (
    route.value?.name?.includes('checkout') &&
    !['/ru/checkout/success', '/checkout/success']?.includes(route.value?.path)
  ) {
  } else {
    return 'auth-authentication'
  }
}

return { __sfc: true,route, props, marketStore, store, activeClrClass, baseClrClass, favoritesCount, cartCount, isAuthenticated, itemClass, linkFrom, toAuthentication }
}

}